import * as React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PageTitle from "../../components/common/page-title";
import Stats from "../../components/home/stats";
import { PricingTableBali } from "../../components/common/pricing-table";
import Table from "../../components/common/table";
import { yttc15days100AshtangaAerial } from "../../data/calendar-data-bali";
import {
  yogaPhilosophy,
  anatomy,
  teachingMethodology,
  aayaTechniques,
} from "../../data/aayaa-teaching-data";
import { GrYoga } from "@react-icons/all-files/gr/GrYoga";
import MainDiveIn from "../../components/home/main-dive-in";
import Meditation from "../../components/common/course-section/meditation";
import BhaktiYoga from "../../components/common/course-section/bhakti-yoga";
import AsthangaVinyasaYoga from "../../components/common/course-section/asthanga-vinyasa-yoga";
import AcroYoga from "../../components/common/course-section/acro-yoga";
import AerialYoga from "../../components/common/course-section/aerial-yoga";
import { pricing100YinAerial } from "../../data/pricing-data-bali";
import { VideoReviewsSmallBali } from "../../components/common/video-reviews-small";
import Hero from "../../components/common/hero";
import { StaticImage } from "gatsby-plugin-image";

const YTTCAshtangaAerial100 = ({ siteTitle }) => (
  <Layout>
    <Seo title="YTTC Ashtanga and Aerial 100 Hours" />
    <div>
      <PageTitle name="100 Hour Ashtanga and Aerial with Acro Yoga Teacher Training" />
      <Hero>
        <StaticImage
          className="w-full rounded-md lg:h-full"
          src="../../images/others/gallery-23.jpeg"
          alt="Aayaa Yoga School Bali"
          layout="fullWidth"
        />
      </Hero>

      <p className="text-base leading-6 text-gray-600 pb-6 mt-6">
        Our 100 hour Ashtanga and Aerial is a part of the 200 hour multi style
        programme designed for students who have a time constraint and also
        cannot commit to very long programmes. This course focusses on two
        styes, yoga philosophy, anatomy, meditation and also a couple of
        sessions of acro yoga alongwith cleansing techniques. The asana clinic
        (alignment and adjustment) classes focuses only on the Ashtanga and
        Aerial poses. a sample schedule is below for your reference.
      </p>
      <p className="text-base leading-6 text-gray-600 pb-6">
        Aerial Yoga or also referred to as Fly Yoga is beneficial in achieving a
        total-body workout, suspend yourself on a hammock flying two to three
        feet off the ground. The fabric acts like a swing, which is slightly
        strechebale allowing flexibility and easy movement. the dynamic
        relationship with the ground changes, to allow us to understand our body
        better. Aerial Yoga also has major health benefits by allowing one to
        stretch and strengthen the body, it also does not stress the joints or
        compressing the vertebrae. The hammock acts as a support system for
        people with back pain or back disorders. Once you get a little practice
        , aerial yoga is fun filled with the benefits of yoga poses. Inversions
        were never so easy.
      </p>
      <p className="text-base leading-6 text-gray-600 pb-6">
        Yin yoga helps you with deep relaxation, calming your emotions,
        stimulating the vital energy of the meridians (similar to nadi) and
        organs, while inducing a deep and intense stretching. Props such as
        cushions, balls, tiles are used to allow the body to enter deeply into
        the asanas thus letting the energy ﬂow and remove the emotional
        blockages that lead to physical unrest. The purpose of yin yoga is to
        relax the muscles and stimulate the connective tissues by applying
        optimal tension for a while whilst maintaining a deep breathing pattern.
      </p>
      <Stats />
      <div className="">
        <h2 className="text-base text-orange-600 font-semibold tracking-wide uppercase">
          15 days of instruction and training
        </h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-4xl">
          Get your Yoga teachers certification in 15 days
        </p>
        <p className="mt-4 text-gray-500">
          At AAYAA Yoga we have created one of the most comprehensive and
          intense course since our aim is to help the Yogi's and the Yogini's
          towards a highly professional and qualified yoga teachers
          certification AAYAA Yoga Teacher Training Course is a certified course
          by Yoga Alliance which is the Certification acknowledged and valued
          worldwide enabling you the permission to teach Yoga. All courses a at
          AAYAA Yoga are devised after in-depth study of Yogic texts and by
          expert teachers who have years of experience in Yoga techniques.
        </p>
        <p className="text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-4xl mt-12">
          Significance of Certification
        </p>
        <p className="mt-4 text-gray-500">
          At AAYAA Our mission is to drive a deep sense of self, well-being,
          liberation, and education to all students through the embodiment of
          our ethical values. Our team consists of highly qualified trainers
          hailing from various backgrounds, each with personal and academic
          experience. Our 100-HOUR Ashtanga and Aerial WITH ACRO YOGA TEACHER
          TRAINING is beneficial for beginners and intermediate students, who
          are willing to teach yoga and choose yoga teaching as a career option,
          and additionally for those who wish to deepen their practice and
          understanding of yoga, or just improve their quality of life. Our
          organizers made their contributions to their life's purpose. Our
          100-Hour Ashtanga and Aerial with Acro Yoga Teacher Training course is
          designed to support in transforming you into most powerful, authentic,
          impactful version of yourself. After the completion of the course, you
          will be registered with Yoga Alliance Certification.
        </p>
      </div>
      <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0 mt-12">
        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            AAYAA Techniques, Training, and Practice
          </h3>
          <p className="my-2 text-gray-700">
            One of the most significant and necessary aspects of the training is
            Yoga teaching techniques. This section of the course comprises
            training, practising, and teaching techniques of classic Yoga
            practises such as Asana (yoga postures) according to the various
            yoga styles that we will teach in the course.
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {aayaTechniques.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            Anatomy and Physiology
          </h3>
          <p className="my-2 text-gray-700">
            This session will cover the following topics: general human body
            movement and terminology. The neurological, endocrine, respiratory,
            skeletal, and muscular systems, as well as their interrelationships,
            in the context of yoga practice.
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {anatomy.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <VideoReviewsSmallBali />

      <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0 mt-12">
        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            Teaching Methodology & Strategy
          </h3>
          <p className="my-2 text-gray-700">
            This module's goal is to teach you the skill of teaching as well as
            the business and marketing sides of yoga: Develop traits of a
            competent teacher such as voice tone, timing, class planning, asana
            sequencing, and the art of correction and alignment. Get the chance
            to hone your skills by teaching in small and large groups with other
            students.
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {teachingMethodology.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            Yoga philosophy, lifestyle & ethics
          </h3>
          <p className="my-2 text-gray-700">
            In yoga philosophy, you will discover the purpose and goals of yoga,
            as well as the ethics and practises of living a yogic lifestyle coz
            being a Yogi you need to have a understanding of
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {yogaPhilosophy.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <section>
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-orange-600">
              Course Details
            </h2>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <AsthangaVinyasaYoga />
            <AerialYoga />
            <AcroYoga />
            <Meditation />
            <BhaktiYoga />
          </div>
        </div>
      </section>

      <PricingTableBali pricing={pricing100YinAerial} />
      <Table
        name="100-HOUR Ashtanga and Aerial YOGA TEACHER TRAINING CALENDAR"
        data={yttc15days100AshtangaAerial}
      />
      <MainDiveIn
        destination={"/reviews#bali"}
        gallery={"/bali/gallery-permana"}
      />
    </div>
  </Layout>
);

export default YTTCAshtangaAerial100;
